<template>
    <icon-base :width="size + 'px'" :fill="color" :viewBox="'0 0 200 200'">
        <path class="st0" d="M197.6,57.8C196,28,169.9,2.1,139.4,0.1C91.1-3,57.2,46.2,77.9,89.7c1.3,2.9,1.2,4.4-1.1,6.6  c-23.9,23.6-47.6,47.4-71.3,71.1c-4.3,4.3-4.3,7-0.1,11.3c4.6,4.6,9.2,9.2,13.8,13.8c4.2,4.1,7.1,4.1,11.3-0.1  c2.4-2.4,4.9-4.7,7.1-7.2c1.6-1.7,2.4-1.4,3.9,0.1c3.9,4.2,8,8.2,12.1,12.2c3.4,3.4,6.4,3.4,9.8,0.1c5.1-5,10.2-10.1,15.2-15.2  c3.2-3.4,3.2-6.5-0.1-9.9c-3.9-4-7.8-8-11.9-11.8c-1.8-1.7-1.9-2.7,0-4.5c11.9-11.8,23.8-23.7,35.6-35.6c1.5-1.6,2.7-2,4.9-0.9  c8.6,4.4,17.8,6.4,28.2,6.4c2.8-0.4,6.5-0.3,10.1-1C177.4,119.8,199.4,91.5,197.6,57.8z M134.5,94.5c-17.2-0.1-31.3-14.4-31.2-31.5  c0.2-17.4,14.2-31.2,31.5-31.1c17.4,0,31.2,13.9,31.1,31.4C165.8,80.6,151.7,94.6,134.5,94.5z"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconKey',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
}
}
</script>

<style scoped>

</style>
